<template>
    <div class="fixture cl center middle" ref="fixt">
        <Renderer ref="renderer" antialias resize alpha :orbit-ctrl="{ enableDamping: true, dampingFactor: 0.05, enableRotate: false, enablePan: false, enableZoom: false,  }" pointer>
            <Camera ref="cam" :position="camera.position" :far="camera.far"/>

            <Scene background="#fff">
                <AmbientLight color="#fff" :intensity="1"/>

                <InstancedMesh ref="imesh" :count="NUM_INSTANCES">
                    <SphereGeometry :radius="SIZE" :widthSegments="8" :heightSegments="8"/>
                    <StandardMaterial :props="{metalness: 0, roughness: 1, vertexColors: true}" />
                </InstancedMesh>

                <Mesh v-if="coords" v-for="item in coords" :position="{x: -item.x, y: item.y, z: item.z }" @click="clickAction($event, item)">
                    <SphereGeometry :radius="mobile ? 0.3 : 0.1" :widthSegments="12" :heightSegments="12"/>
                    <StandardMaterial :color="item.color" :props="{metalness: 0.1, roughness: 1}" />
                </Mesh>
            </Scene>

            <EffectComposer>
                <RenderPass />
            </EffectComposer>
        </Renderer>

        <div class="popup-fixture light" v-show="popup" ref="_popup">
            <div class="popup" @click="togglePopupIfOpen">
                <div class="rw base nw title" v-if="popup.title">
                    <i><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16675 11.8748L1.29175 10.9998L9.87508 2.4165H2.00008V1.1665H12.0001V11.1665H10.7501V3.2915L2.16675 11.8748Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/></svg></i>
                    <span v-html="popup.title"/>
                </div>

                <p class="_small nm" v-html="popup.content"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { AnimationMixer, Clock, Fog, GridHelper, Vector3, Object3D, Matrix4, InstancedBufferAttribute, Color } from 'three'
    import { AmbientLight, BoxGeometry, Camera, InstancedMesh, Renderer, Scene } from 'troisjs'
    import * as THREE from 'three'
    import gsap from 'gsap'
    import { Box, SphereGeometry } from 'troisjs'
</script>

<script>
    export default {
        components: {
            AmbientLight,
            BoxGeometry,
            Camera,
            InstancedMesh,
            Renderer,
            Scene,
        },

        props: ["settings", "coords", "SIZE", "PADDING", "NX", "NY", "SIZEP", "W", "H", "NUM_INSTANCES", "COLORS"],

        data() {
            return {
                large: false,
                mpos: {
                    x: 0,
                    y: 0
                },
                popup: false,
                popupState: false
            }
        },

        mounted() {
            let matrix = new Matrix4()
            let pixels = []

            this.renderer = this.$refs.renderer
            this.pointer  = this.renderer.three.pointer
            this.imesh    = this.$refs.imesh.mesh
            this.cam      = this.$refs.cam.camera
            this.light    = this.$refs.light ? this.$refs.light.light : false
            this.opa      = []
            this.zindex   = []

            this.COLORS.forEach(pixel => {
                let tot = 0
                pixel.forEach(val => {
                    tot += val 
                    pixels.push(val/255)
                })
                this.opa.push(Number(tot < 650))
                this.zindex.push(0)
            })

            this.imesh.geometry.setAttribute('color', new InstancedBufferAttribute(new Float32Array(pixels), 3))
            this.dummy = new Object3D()
            this.renderer.onBeforeRender(this.animate)
            this.dotModelLoaded()
        },

        methods: {
            togglePopup(item, close=false) {
                if (close) {
                    gsap.to(this.$refs._popup, 0.64, {
                        ease: 'expo.out',
                        opacity: 0,
                        y: this.$refs._popup.y + 100,
                        onComplete: () => {
                            this.popup = false
                            this.popupState = false
                        }
                    })
                } else {
                    this.popup = item

                    gsap.to(this.$refs._popup, 0.64, {
                        ease: 'expo.out',
                        opacity: 1,
                        x: this.popup.x * this.offset_w,
                        y: this.popup.y * this.offset_h,
                        onComplete: () => {
                            this.popupState = true
                        }
                    })
                }
            },

            togglePopupIfOpen() {
                if (this.popupState) this.togglePopup(false, true)
            },

            clickAction(event, item, close=false) {
                if (close || this.popupState && this.popup.hasOwnProperty('content') && this.popup.content == item.content) {
                    this.togglePopup(false, true)
                } else {
                    this.togglePopup(item)
                }
            },

            dotModelLoaded() {
                let x0 = -this.W / 2 + this.PADDING;
                let y0 = -this.H / 2 + this.PADDING;
                let time = Date.now() * 0.0001;
                let mx = this.pointer.positionN.x * 0.1;
                let my = this.pointer.positionN.y * 0.1;
                let noise = 0.005;
                let x, y, nx, ny, rx, ry;
                let tot = 0

                // OnHover
                let rr = 0.32, // Mouse radius,
                    mO = -0.1, // maxOffset
                    sR = 0.01  // speed

                for (let i = 0; i < this.NX; i++) {
                    for (let j = 0; j < this.NY; j++) {
                        x = x0 + i * this.SIZEP
                        y = y0 + j * this.SIZEP

                        if (Math.sqrt((x-this.mpos.x)*(x-this.mpos.x) + (y-this.mpos.y)*(y-this.mpos.y)) < rr) {
                            let positionInRadius = [this.mpos.x - x, this.mpos.y - y]
                            if (positionInRadius[0] > 0) positionInRadius[0] = -positionInRadius[0]
                            if (positionInRadius[1] > 0) positionInRadius[1] = -positionInRadius[1]

                            let maxZx = mO - positionInRadius[0]
                            let maxZy = mO - positionInRadius[1]
                            let maxZ  = Math.min(mO, Math.max(maxZx, maxZy))

                            if (this.zindex[tot] !== maxZ) {
                                this.zindex[tot] -= sR
                                this.zindex[tot] = Math.max(this.zindex[tot], maxZ)
                            }
                        } else if (this.zindex[tot] < 0) {
                            this.zindex[tot] += sR / 5
                        }

                        if (this.opa[tot]) this.dummy.position.set(x, y, Math.max(Math.min(this.zindex[tot], 0), mO))
                        else this.dummy.position.set(0, 0, -40)

                        this.dummy.updateMatrix()
                        this.imesh.setMatrixAt(i * this.NY + j, this.dummy.matrix)
                        tot++
                    }
                }

                this.imesh.instanceMatrix.needsUpdate = true;
            },

            animate() {
                this.mpos = this.pointer.positionV3

                if (this.light) {
                    this.light.position.x = this.mpos.x
                    this.light.position.y = this.mpos.y
                    // this.light.position.z = this.mpos.z
                }

                this.dotModelLoaded()
            },

            updateInstanceMatrix() {

            },

            modelLoaded(object) {
                // let texture = [
                //     "media/images/test.original.png", 
                //     "media/images/test.original.png"
                // ]

                // object.scene.traverse(function (child) {
                //     if (child.isMesh) {
                //         child.castShadow = true
                //         child.receiveShadow = true
                //     }
                // });
            },

            animationLoaded(object) {
                this.mixer = new AnimationMixer(object)
                const action = this.mixer.clipAction(object.animations[0])
                action.play()

                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.castShadow = true
                        child.receiveShadow = true
                    }
                })

                this.clock = new Clock()
                this.$refs.renderer.onBeforeRender(this.updateMixer)
            },

            updateMixer() {
                this.mixer.update(this.clock.getDelta())
            }
        },

        computed: {
            mobile() {
                return document.body.clientWidth < 704
            },

            camera() {
                return {
                    position: { 
                        z: this.mobile ? -6 : -5, 
                        y: 0, 
                        x: 0
                    },
                    far: 10
                }
            },

            offset_w() {
                return (Math.min(this.$refs.fixt.querySelector('canvas').offsetWidth, 1920) / 2) / 2.8
            },

            offset_h() {
                return -(Math.min(this.$refs.fixt.querySelector('canvas').offsetHeight, 1920) / 2) / 2.8
            },
        },
    }
</script>