<template>
    <div class="team-slider p-y-bottom">

        <swiper 
            :modules="modules" 
            :centered-slides="true" 
            :loop="true"
            :initial-slide="Math.round((finalContent.length - 1) / 2)" 
            slides-per-view="auto"
            :watch-slides-progress="true"
            :slide-to-clicked-slide="true"
            :speed="4800" 
            :autoplay="autoplay"
            :space-between="16">

            <swiper-slide v-for="teamMember in finalContent">
                <article class="member slide" :class="color">
                    <figure>
                        <img :src="teamMember.image" :alt="teamMember.name">
                    </figure>

                    <div class="inner">
                        <h1 class="_sans" v-html="teamMember.name"/>    
                        <span class="_sans" v-html="teamMember.function"/>    
                    </div>
                </article>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { FreeMode, Controller, Autoplay } from 'swiper/modules';
</script>

<script>
    export default {
        props: ['content', 'color'],

        data() {
            return {
                modules: [FreeMode, Controller, Autoplay],
                autoplay: {
                    delay: 0, 
                    disableOnInteraction: false
                }
            }
        },

        methods: {
            onSwiper(swiper) {
                console.log('asdasdasdasd')
            },

            onSlideChange() {
                console.log('asdasdasdasd')
            },
        },

        computed: {
            finalContent() {
                return this.content.concat(this.content).concat(this.content).concat(this.content).concat(this.content)
            }
        }
    };
</script>
