import { createApp } from 'vue'
import { TroisJSVuePlugin } from 'troisjs'

import gsap from 'gsap'

// Vue components 
import Model from './components/Model.vue'
import Maped from './components/Maped.vue'
import Slider from './components/Slider.vue'
import Timeline from './components/Timeline.vue'
import Gallery from './components/Gallery.vue'
import HeaderBlock from './components/HeaderBlock.vue'
import MethodSlider from './components/MethodSlider.vue'
import TeamSlider from './components/TeamSlider.vue'
import Stakeholders from './components/Stakeholders.vue'
import ThroisImageLoader from './components/ThroisImageLoader.vue'

import * as translations from './modules/translations'
import ClickOutside from './modules/click-outside.js';

let components = document.querySelectorAll('.component')

document.addEventListener("DOMContentLoaded", e => {
	let highlights = document.querySelectorAll('.highlight-fixture')

	highlights.forEach(highlight => {
        gsap.to(highlight, 1, {
            ease: 'expo.out',
            opacity: 0,
            y: '0rem'
        })
	})

    let fadeOuts = document.querySelectorAll('._fadeout')

    if (fadeOuts) {
        fadeOuts.forEach(item => {
            gsap.to(item, 0.48, {
                delay: 0.48,
                ease: 'sine',
                css: {
                    opacity: 1,
                }
            })
        })
    }

    if (components) {
		components.forEach(component => {
			let app = createApp()

			app.directive('click-outside', ClickOutside);

			// Register components 
			app.component('Model', Model)
			app.component('Maped', Maped)
			app.component('Slider', Slider)
			app.component('Gallery', Gallery)
			app.component('Timeline', Timeline)
			app.component('HeaderBlock', HeaderBlock)
			app.component('TeamSlider', TeamSlider)
			app.component('MethodSlider', MethodSlider)
			app.component('Stakeholders', Stakeholders)
			app.component('ThroisImageLoader', ThroisImageLoader)

			// Register uses
			app.use(TroisJSVuePlugin)
			app.config.globalProperties.translations = 'translations'

			// Start Vue app
			app.mount(component)
		})
	}
})