<template>
    <div class="trois cl center" style="min-height: 64rem;">
        <Maped v-if="loaded" :coords="content.waypoints" :COLORS="dataset['COLORS']" :SIZE="dataset['SIZE']" :PADDING="dataset['PADDING']" :NX="dataset['NX']" :NY="dataset['NY']" :SIZEP="dataset['SIZEP']" :W="dataset['W']" :H="dataset['H']" :NUM_INSTANCES="dataset['NUM_INSTANCES']" :pointPos="dataset['pointPos']"/>
        <canvas ref="canvas" style="opacity: 0; pointer-events: 0; display: flex; position: fixed; bottom: 0; left: 0; transform: scale(1); transform-origin: top left;"></canvas>
    </div>
</template>

<script setup>
    import * as THREE from 'three'
</script>

<script type="text/javascript">
    export default {
        name: 'ThroisImageLoader',
        props: ['content'],

        data() {
            return {
                loaded: false,
                dataset: {},
                image: false,
            }
        },

        mounted() {
            const canvas = this.$refs.canvas

            if (canvas.getContext) {
                let that = this
                const ctx = canvas.getContext("2d")

                let base_image = new Image()
                base_image.src = this.content.image
                base_image.onload = function(){
                    ctx.drawImage(base_image, 0,0)

                    let imgData = ctx.getImageData(0, 0, base_image.width, base_image.height)
                    let rgbArray = []
                    let rgbArray2 = []
                    let realList = []
                    let yRow = []

                    for (var i = 0; i < imgData.data.length; i+=4) {
                        rgbArray.push([imgData.data[i], imgData.data[i+1], imgData.data[i+2]])
                    }

                    // for (var i = imgData.data.length.length - 1; i >= 0; i--) {
                    //     rgbArray.push([imgData.data[i], imgData.data[i+1], imgData.data[i+2]])
                    // }

                    for (var i = rgbArray.length - 1; i >= 0; i--) {
                        yRow.push(rgbArray[i])

                        if (yRow.length >= base_image.width) {
                            rgbArray2.push(yRow)
                            yRow = []
                        }
                    }

                    function createGrid(rowCount, columnCount) {
                        for (let x = 0; x < rowCount; x++) {
                            for (let y = 0; y < columnCount; y++) {
                                cell(x, y);
                            }
                        }
                    }

                    function cell(x, y) {
                        realList.push(rgbArray2[y][x])
                    }

                    var grid = []
                    createGrid(base_image.width, base_image.height)

                    // Set defaults
                    that.dataset['SIZE']    = 0.02
                    that.dataset['PADDING'] = 0.005
                    that.dataset['NX']      = base_image.width
                    that.dataset['NY']      = base_image.height
                    that.dataset['SIZEP']   = that.dataset['SIZE'] * 2 + that.dataset['PADDING']
                    that.dataset['W']       = that.dataset['NX'] * that.dataset['SIZEP'] - that.dataset['PADDING']
                    that.dataset['H']       = that.dataset['NY'] * that.dataset['SIZEP'] - that.dataset['PADDING']
                    that.dataset['NUM_INSTANCES'] = that.dataset['NX'] * that.dataset['NY']
                    that.dataset['RW']      = realList
                    that.dataset['COLORS']  = realList

                    that.loaded = true
                }
            }
        }
    }
</script>