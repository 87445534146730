<template>
    <header ref="header" class="block header-wrapper" :class="[scrolled ? 'scrolled' : '', method ? 'method-' + method.toLowerCase() : '']">
        <div class="header-container" :class="[color]">
            <div class="cols cl center">
                <div class="col sm-12 md-12 lg-10 cl center" v-if="content.heading">
                    <div class="inner">
                        <h1 class="_dot _hero ct">{{ content.heading }}</h1>

                        <div class="rw center button-wrap" v-if="buttons">
                            <a v-for="button in buttons" :href="button.url" class="button" :class="button.type" :aria-label="button.label">
                                <i v-if="button.type == 'secondary'"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16675 11.8748L1.29175 10.9998L9.87508 2.4165H2.00008V1.1665H12.0001V11.1665H10.7501V3.2915L2.16675 11.8748Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/></svg></i>
                                <span>{{ button.label }}</span>
                            </a>
                        </div>

                        <div class="post-wrap cl center middle" v-if="post">
                            <div class="byline rw center base op-64">
                                <span class="date" v-html="__('Posted %%', [formatDate(post.date)])"/>
                                <div class="tags" v-if="post.tags.length">
                                    <span v-for="tag in post.tags" v-html="'#' + tag.name"/>
                                </div>
                            </div>

                            <p class="intro _intro_p _centered" v-html="post.intro"/>
                        </div>
                    </div>
                </div>

                <div v-if="content.image" class="col sm-12 md-12 lg-12 np cl center">
                    <figure ref="interactiveImage" class="oh image _fadein" @click="clickFigure">
                        <video v-if="content.video" ref="video" width="1920" height="1980" :src="content.video" type="video/webm" frameborder="0" allowfullscreen loop muted></video>
                        <img v-else class="_offset_top" :src="content.image" :alt="content.heading"/>
                    </figure>
                </div>
            </div>

            <figure v-if="content.bg" ref="bgImage" class="bg _fadein" :data-opacity="content.bg_type == 'only' ? '0.8' : '0.12'">
                <img :src="content.bg" :alt="content.heading"/>
            </figure>

            <div class="menu-wrapper">
                <div class="menu-fixture cols rw middle space">
                    <div class="col sm-12 md-12 rw space middle menu-actions">
                        <div v-if="content.languages.length > 1" class="lang-selector" v-click-outside="toggleLangIfOpen">
                            <span class="lang-current" @click="langSelect = !langSelect">{{ langCurrent.label }}</span>

                            <ul v-show="langSelect" class="light" :class="[langSelect ? 'active': '', !color || color == 'light' ? 'grey' : '']">
                                <li v-for="lang in content.languages" :class="method ? 'method-' + method.toLowerCase() : ''">
                                    <a :href="lang.url" rel="alternate" :aria-label="lang.label">
                                        <span v-html="lang.label"/>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div v-else></div>

                        <div class="oqin-logo rw nw center middle" :class="method ? 'method-' + method.toLowerCase() : ''">
                            <a :href="'/' + (langCurrent.code != 'en' ? langCurrent.code : '')" aria-label="Oqin homepage">
                                <svg width="123" height="64" viewBox="0 0 123 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0513 50.8718C28.0207 50.8718 36.1026 42.79 36.1026 32.8205C36.1026 22.8511 28.0207 14.7692 18.0513 14.7692C8.08183 14.7692 0 22.8511 0 32.8205C0 42.79 8.08183 50.8718 18.0513 50.8718ZM18.0513 44.3077C23.4892 44.3077 27.8974 39.1647 27.8974 32.8205C27.8974 26.4763 23.4892 21.3333 18.0513 21.3333C12.6134 21.3333 8.20513 26.4763 8.20513 32.8205C8.20513 39.1647 12.6134 44.3077 18.0513 44.3077Z" fill="currentColor"/>
                                    <rect x="77.1282" y="15.5898" width="9.02564" height="34.4615" rx="4.51282" fill="currentColor"/>
                                    <rect x="91.0769" y="14.7692" width="9.02564" height="35.2821" rx="4.51282" fill="currentColor"/>
                                    <rect x="113.231" y="22.1538" width="9.02564" height="27.8974" rx="4.51282" fill="currentColor"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.3332 50.8718C61.49 50.8718 68.1024 42.79 68.1024 32.8205C68.1024 22.8511 61.49 14.7692 53.3332 14.7692C45.1764 14.7692 38.564 22.8511 38.564 32.8205C38.564 42.79 45.1764 50.8718 53.3332 50.8718ZM54.9742 43.4872C59.9589 43.4872 63.9999 39.4734 63.9999 32.8205C63.9999 26.1676 59.9589 22.1538 54.9742 22.1538C49.9895 22.1538 45.9486 25.4057 45.9486 32.8205C45.9486 40.2354 49.9895 43.4872 54.9742 43.4872Z" fill="currentColor"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M67.6923 14.7692C65.1999 14.7692 63.1794 16.7897 63.1794 19.282V28.0283C63.7062 29.4869 64 31.11 64 32.8205C64 34.531 63.7062 36.1541 63.1794 37.6127V59.4872C63.1794 61.9795 65.1999 64 67.6923 64C70.1846 64 72.2051 61.9795 72.2051 59.4872V19.282C72.2051 16.7897 70.1846 14.7692 67.6923 14.7692Z" fill="currentColor"/>
                                    <ellipse cx="81.6409" cy="6.15385" rx="6.15385" ry="6.15385" fill="currentColor" class="_logodot"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M113.231 38.0549C118.454 36.5749 122.256 32.0362 122.256 26.6667C122.256 20.0959 116.562 14.7692 109.539 14.7692C102.515 14.7692 96.8206 20.0959 96.8206 26.6667C96.8206 29.2993 97.7346 31.7323 99.2821 33.703V29.5385C99.2821 25.46 102.405 22.1538 106.256 22.1538C110.108 22.1538 113.231 25.46 113.231 29.5385V38.0549Z" fill="currentColor"/>
                                </svg>

                                <div v-if="method" class="method-indicator" ref="_method" :class="method.toLowerCase()">
                                    <span class="_code">{{ method }}</span>
                                </div>

                                <div v-else-if="mapleleaf" ref="mapleleaf" class="canada" :class="color">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="630" height="655" viewBox="-1953 -2031 3906 4061"><path fill="currentColor" d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"></path></svg>
                                </div>
                            </a>
                        </div>

                        <div class="menu-toggle rw middle" rel="nofollow" @click="toggleMenu()">
                            <span class="hide-sm-down" v-html="__(menuState ? 'Close' : 'Menu')"/>

                            <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="2" fill="currentColor"/>
                                <rect x="8" y="13" width="24" height="2" fill="currentColor"/>
                                <rect y="26" width="32" height="2" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="menu-side cl space" :class="[color != 'accent' ? color : 'dark']" ref="sideMenu" v-click-outside="toggleMenuIfOpen">
                    <div class="menu-top rw space middle">
                        <div class="oqin-logo-menu rw nw center middle" :class="method ? 'method-' + method.toLowerCase() : ''">
                            <a :href="'/' + (langCurrent.code != 'en' ? langCurrent.code : '')" aria-label="Oqin homepage">
                                <svg width="123" height="64" viewBox="0 0 123 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0513 50.8718C28.0207 50.8718 36.1026 42.79 36.1026 32.8205C36.1026 22.8511 28.0207 14.7692 18.0513 14.7692C8.08183 14.7692 0 22.8511 0 32.8205C0 42.79 8.08183 50.8718 18.0513 50.8718ZM18.0513 44.3077C23.4892 44.3077 27.8974 39.1647 27.8974 32.8205C27.8974 26.4763 23.4892 21.3333 18.0513 21.3333C12.6134 21.3333 8.20513 26.4763 8.20513 32.8205C8.20513 39.1647 12.6134 44.3077 18.0513 44.3077Z" fill="currentColor"/>
                                    <rect x="77.1282" y="15.5898" width="9.02564" height="34.4615" rx="4.51282" fill="currentColor"/>
                                    <rect x="91.0769" y="14.7692" width="9.02564" height="35.2821" rx="4.51282" fill="currentColor"/>
                                    <rect x="113.231" y="22.1538" width="9.02564" height="27.8974" rx="4.51282" fill="currentColor"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.3332 50.8718C61.49 50.8718 68.1024 42.79 68.1024 32.8205C68.1024 22.8511 61.49 14.7692 53.3332 14.7692C45.1764 14.7692 38.564 22.8511 38.564 32.8205C38.564 42.79 45.1764 50.8718 53.3332 50.8718ZM54.9742 43.4872C59.9589 43.4872 63.9999 39.4734 63.9999 32.8205C63.9999 26.1676 59.9589 22.1538 54.9742 22.1538C49.9895 22.1538 45.9486 25.4057 45.9486 32.8205C45.9486 40.2354 49.9895 43.4872 54.9742 43.4872Z" fill="currentColor"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M67.6923 14.7692C65.1999 14.7692 63.1794 16.7897 63.1794 19.282V28.0283C63.7062 29.4869 64 31.11 64 32.8205C64 34.531 63.7062 36.1541 63.1794 37.6127V59.4872C63.1794 61.9795 65.1999 64 67.6923 64C70.1846 64 72.2051 61.9795 72.2051 59.4872V19.282C72.2051 16.7897 70.1846 14.7692 67.6923 14.7692Z" fill="currentColor"/>
                                    <ellipse cx="81.6409" cy="6.15385" rx="6.15385" ry="6.15385" fill="currentColor" class="_logodot"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M113.231 38.0549C118.454 36.5749 122.256 32.0362 122.256 26.6667C122.256 20.0959 116.562 14.7692 109.539 14.7692C102.515 14.7692 96.8206 20.0959 96.8206 26.6667C96.8206 29.2993 97.7346 31.7323 99.2821 33.703V29.5385C99.2821 25.46 102.405 22.1538 106.256 22.1538C110.108 22.1538 113.231 25.46 113.231 29.5385V38.0549Z" fill="currentColor"/>
                                </svg>
                            </a>
                        </div>

                        <div class="menu-toggle rw middle" rel="nofollow" @click="toggleMenu()">
                            <span v-html="__('Close')"/>

                            <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="2" fill="currentColor"/>
                                <rect x="8" y="13" width="24" height="2" fill="currentColor"/>
                                <rect y="26" width="32" height="2" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>

                    <nav class="cl middle">
                        <span class="list-heading" v-html="__('Menu')"/>

                        <ul class="menu cl stretch">
                            <li v-for="item in content.menu">
                                <a :href="item.url" :class="content.id == item.id ? 'active' : ''">
                                    <svg v-if="content.id == item.id" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16675 11.8748L1.29175 10.9998L9.87508 2.4165H2.00008V1.1665H12.0001V11.1665H10.7501V3.2915L2.16675 11.8748Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"></path></svg>
                                    <span v-html="item.label"/>
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <div class="cl">
                        <template v-if="content.socials.length > 0">
                            <span class="list-heading" v-html="__('Follow us')"/>

                            <ul class="socials rw middle">
                                <li v-for="platform in content.socials">
                                    <a :href="platform.url" rel="nofollow" target="_blank" :aria-label="'Follow us on ' + platform.name">
                                        <svg v-if="platform.name == 'x'" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66" fill="currentColor"/></svg>
                                        <svg v-else-if="platform.name == 'linkedin'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
                                        <svg v-else-if="platform.name == 'youtube'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>
                                        <svg v-else-if="platform.name == 'instagram'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                                        <svg v-if="platform.name == 'facebook'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="cols rw nw end hide-sm-down" v-if="hasHighlight">
            <div class="col sm-12 md-6 lg-4">
                <div class="highlight-fixture light" ref="highlight">
                    <a :href="content.highlight.url" class="highlight">
                        <div class="rw base nw title" v-if="content.highlight.short_title">
                            <i><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16675 11.8748L1.29175 10.9998L9.87508 2.4165H2.00008V1.1665H12.0001V11.1665H10.7501V3.2915L2.16675 11.8748Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/></svg></i>
                            <span v-html="content.highlight.short_title"/>
                        </div>

                        <p class="_small nm" v-html="content.highlight.short_description"/>
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
    import gsap from 'gsap'
    import moment from 'moment'
    import * as translations from '../modules/translations'
</script>

<script>
    export default {
        props: ['content'],
        // components: [MainMenu],

        data() {
            return {
                loaded: false,
                scrolled: false,
                scrollTop: (window.pageYOffset || document.documentElement.scrollTop),
                startPos: 200,
                menuState: false,
                langSelect: false,
                paused: true
            }
        },

        created () {
            window.addEventListener('scroll', this.handleScroll);
        },

        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },

        mounted() {
            if (this.method) {
                gsap.to(this.$refs._method, 0.24, {
                    delay: 0.48,
                    ease: 'expo.out',
                    css: {
                        opacity: 1,
                        width: "4.5rem"
                    }
                })
            }

            let fadeIns = document.querySelectorAll('._fadein')

            if (fadeIns) {
                fadeIns.forEach(item => {
                    gsap.to(item, 1.24, {
                        delay: 0.48,
                        ease: 'sine',
                        css: {
                            opacity: item.dataset.opacity ? item.dataset.opacity : 1,
                        },

                        onComplete: () => {
                            item.classList.remove('_fadein')
                        }
                    })
                })
            }

            let offsetTops = document.querySelectorAll('._offset_top')

            if (offsetTops) {
                offsetTops.forEach(item => {
                    gsap.to(item, 0.32, {
                        delay: 0.48,
                        ease: 'sine.out',
                        y: 0
                    })
                })
            }

            if (this.mapleleaf) {
                setTimeout(() => {
                    this.$refs.mapleleaf.classList.add('loaded')
                }, 1000);
            }

            if (this.hasHighlight) {
                gsap.set(this.$refs.highlight, {
                    css: {
                        opacity: 0,
                        translateY: '3rem',
                    },
                    onComplete: () => {
                        gsap.to(this.$refs.highlight, 0.4, {
                            delay: 2,
                            css: {
                                opacity: 1,
                                translateY: '0',
                            }, 
                        })
                    }
                }) 
            }
        },

        methods: {
            __(string, vars=[]) {
                return translations.translate(string, vars) 
            },

            clickFigure() {
                if (this.$refs.video) {
                    if (this.paused) {
                        this.$refs.video.play()
                        this.paused = false
                    } else {
                        this.$refs.video.pause()
                        this.paused = true
                    }
                }
            },

            formatDate(datetime) {
                console.log(datetime)
                return moment(datetime).format('DD/MM')
            },

            handleScroll (event) {
                this.scrollTop = (window.pageYOffset || document.documentElement.scrollTop)

                if (this.content.animate) {
                    if (!this.scrolled && this.scrollTop > this.startPos || this.scrolled && this.scrollTop < this.startPos) {
                        this.scrolled = !this.scrolled

                        gsap.to(this.$refs.interactiveImage, 0.4, {
                            ease: 'expo.out',
                            height: (this.scrolled ? this.$refs.interactiveImage.querySelector('img, video').offsetHeight + this.offset_b : 160)
                        })

                        if (this.$refs.video) {
                            this.scrolled ? this.$refs.video.play() : this.$refs.video.pause()
                        }
                    }
                }
            },

            toggleLangIfOpen() {
                if (this.langSelect) this.langSelect = false
            },

            toggleMenu(close=false) {
                let newState = false

                if (close) newState = false
                else newState = !this.menuState

                gsap.to(this.$refs.sideMenu, 0.64, {
                    ease: 'expo.out',
                    x: newState ? 0 : this.$refs.sideMenu.offsetWidth,
                    onComplete: () => {
                        this.menuState = newState
                    }
                })
            },

            toggleMenuIfOpen() {
                if (this.menuState) this.toggleMenu(true)
            },
        },

        computed: {
            langCurrent() {
                return this.content.languages[0]
            },

            method() {
                return this.content.hasOwnProperty('method') ? this.content.method : false
            },

            buttons() {
                return this.content.hasOwnProperty('buttons') && this.content.buttons && this.content.buttons.length ? this.content.buttons : false
            },

            hasHighlight() {
                return this.content.hasOwnProperty('highlight') && this.content.highlight && (this.content.highlight.short_title || this.content.highlight.short_description)
            },

            color() {
                return this.content.hasOwnProperty('color') && this.content.color ? this.content.color : false
            },

            post() {
                return this.content.hasOwnProperty('post') && this.content.post ? this.content.post : false
            },

            offset_b() {
                return document.body.clientWidth < 704 ? 0 : 64
            },

            mapleleaf() {
                return this.content.hasOwnProperty('mapleleaf') && this.content.mapleleaf
            }
        }
    };
</script>
