<template>
    <div class="stakeholder-wrapper">
        <div class="cols np">
            <div class="col sm-12 md-12 rw stretch space" v-if="content.length">
                <div class="stakeholder-content">
                    <a :href="stakeholder.url" class="item _dots" v-for="(stakeholder, index) in content" @mouseover.stop="setActive(stakeholder, index)" @mouseleave.stop="setActive(stakeholder, index)">
                        <div class="stakeholder">
                            <i v-html="stakeholder.icon"></i>
                            <!-- <svg class="icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 36L14 20L23.75 33H40L28 17.05L21.75 25.35L19.85 22.85L28 12L46 36H2ZM8 33H20L14 25L8 33Z" fill="currentColor"/>
                            </svg> -->
                            <div class="cl">
                                <h1 class="_dot">{{ stakeholder.name }}</h1>
                                <div v-html="stakeholder.description"/>

                                <div class="button text nt accent">
                                    <i><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16675 11.8748L1.29175 10.9998L9.87508 2.4165H2.00008V1.1665H12.0001V11.1665H10.7501V3.2915L2.16675 11.8748Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/></svg></i>
                                    <span v-html="__('More info')"/>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="stakeholder-slider">
                    <Slider ref="slider" :images="images" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import * as translations from '../modules/translations'
</script>

<script>
    export default {
        name: 'Stakeholders',
        props: ['content'],

        data() {
            return {
                currentIndex: 0
            }
        },

        mounted() {
        },

        methods: {
            __(string) {
                return translations.translate(string) 
            },
            setActive(stakeholder, index) {
                if (index != this.currentIndex) {
                    this.currentIndex = index
                    this.$refs.slider.setTargetProgress(this.currentIndex)
                }
            }
        },

        computed: {
            camera() {
                return {
                    position: { 
                        z: -5, 
                        y: 0, 
                        x: 0
                    },
                    far: 50
                }
            },

            images() {
                let images = []

                this.content.forEach(item => {
                    images.push({ src: item.image })
                })

                return images
            }
        },
    }
</script>