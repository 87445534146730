<template>
    <div class="method-wrapper" :class="'method-' + currentCode">
        <div class="cols rw space middle p-y">
            <div class="col sm-12 md-4 lg-6 rw middle method-slider">
                <figure>
                    <svg class="logo ov" viewBox="0 0 320 168" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="213.691" cy="16.2544" rx="16.1074" ry="16.1074" class="logo-dot"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.2483 133.301C73.3428 133.301 94.4966 112.148 94.4966 86.053C94.4966 59.9585 73.3428 38.8047 47.2483 38.8047C21.1538 38.8047 0 59.9585 0 86.053C0 112.148 21.1538 133.301 47.2483 133.301ZM47.2483 116.12C61.4817 116.12 73.0201 102.659 73.0201 86.053C73.0201 69.4474 61.4817 55.9859 47.2483 55.9859C33.0149 55.9859 21.4765 69.4474 21.4765 86.053C21.4765 102.659 33.0149 116.12 47.2483 116.12Z" fill="#101926"/>
                        <path d="M201.879 52.7645C201.879 46.2409 207.167 40.9524 213.691 40.9524C220.215 40.9524 225.503 46.2409 225.503 52.7645V119.342C225.503 125.865 220.215 131.154 213.691 131.154C207.167 131.154 201.879 125.865 201.879 119.342V52.7645Z" fill="#101926"/>
                        <path d="M250.201 38.8047C243.678 38.8047 238.389 44.0931 238.389 50.6168V119.342C238.389 125.865 243.678 131.154 250.201 131.154C256.725 131.154 262.013 125.865 262.013 119.342V68.3585C265.087 62.2734 271.147 58.1335 278.121 58.1335C288.203 58.1335 296.376 66.7874 296.376 77.4624L296.376 119.342C296.376 125.865 301.664 131.154 308.188 131.154C314.712 131.154 320 125.865 320 119.342V69.9456C320 69.9181 320 69.8906 320 69.8632C319.952 52.7024 305.067 38.8047 286.711 38.8047C276.866 38.8047 268.019 42.8031 261.924 49.1587C261.206 43.323 256.231 38.8047 250.201 38.8047Z" fill="#101926"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M139.597 133.301C149.498 133.301 158.53 128.752 165.369 121.27V155.852C165.369 162.375 170.658 167.664 177.181 167.664C183.705 167.664 188.993 162.375 188.993 155.852V50.6168C188.993 44.0931 183.705 38.8047 177.181 38.8047C170.658 38.8047 165.369 44.0932 165.369 50.6168V50.8358C158.53 43.3541 149.498 38.8047 139.597 38.8047C118.247 38.8047 100.939 59.9585 100.939 86.053C100.939 112.148 118.247 133.301 139.597 133.301ZM143.892 58.1335C153.647 58.1335 162.021 64.006 165.626 74.2473C166.799 77.7387 167.467 81.5698 167.514 85.5924C167.515 85.668 167.516 85.7437 167.516 85.8196C167.516 85.8973 167.517 85.9751 167.517 86.053C167.517 86.2071 167.516 86.3606 167.514 86.5136C167.467 90.5361 166.799 94.3671 165.626 97.8585C162.021 108.1 153.647 113.972 143.892 113.972C130.845 113.972 120.268 105.461 120.268 86.053C120.268 66.645 130.845 58.1335 143.892 58.1335Z" fill="#101926"/>
                    </svg>
                </figure>

                <swiper
                    class="thumbs"
                    ref="thumbs"
                    :modules="modules"
                    :autoplay="{
                        delay: 3000, 
                        disableOnInteraction: true
                    }"
                    :speed="640" 
                    :slide-to-clicked-slide="true"
                    :controller="{ control: controlledSwiper }"
                    slides-per-view="1"
                    :breakpoints="breakpoints">
                    <swiper-slide v-for="item in content">
                        <div class="method-indicator" :class="item.code.toLowerCase()">
                            <span class="_code">{{ item.code }}</span>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="col sm-12 md-8 lg-6 method-slider content">
                <swiper class="content" 
                    :modules="[Controller]" 
                    @slideChange="onSlideChange" 
                    @swiper="setControlledSwiper" 
                    :controller="{ control: controlledSwiper }" 
                    :space-between="0" 
                    :speed="640" 
                    :slides-per-view="1">
                    <swiper-slide v-for="item in content" :data-code="item.code.toLowerCase()" class="cl middle">
                        <article class="slide cl middle _content">
                            <h2 class="_dot _lspace" v-html="item.short_title"/>    
                            <span v-html="item.short_description"/>    
                            <a :href="item.url" class="button primary _active" :class="item.code.toLowerCase()">
                                <i><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.16675 11.8748L1.29175 10.9998L9.87508 2.4165H2.00008V1.1665H12.0001V11.1665H10.7501V3.2915L2.16675 11.8748Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"></path></svg></i>
                                <span v-html="__('Learn more about %%', [item.name])"/>
                            </a>
                        </article>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { Controller, Autoplay } from 'swiper/modules';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import * as translations from '../modules/translations'

    export default {
        props: ['content'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
                modules: [Controller, Autoplay],
                currentCode: 'blue',
                breakpoints: {
                    0: {
                        direction: "horizontal"
                    },
                    704: {
                        direction: "vertical"
                    }
                }
            }
        },

        setup() {
            const controlledSwiper = ref(null);
            const setControlledSwiper = (swiper) => {
                controlledSwiper.value = swiper;
            };

            return {
                Controller,
                controlledSwiper,
                setControlledSwiper,
            };
        },

        mounted() {
            this.currentCode = this.content[0].code.toLowerCase()
        },

        methods: {
            __(string, vars) {
                return translations.translate(string, vars)
            },

            onSlideChange(e) {
                this.currentCode = e.slides[e.activeIndex].dataset.code
            },
        },

        computed: {
            mobile() {
                return window.innerWidth < 1024
            },

            direction () {
                return this.mobile ? "horizontal" : "vertical"
            }
        }
    };

</script>
