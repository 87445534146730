<template>
    <div class="timeline-fixture cols p-y cl _content">
        <article v-for="item, index in content" class="col sm-12 md-5 timeline-item cl nw middle">
            <div class="inner cl">
                <div class="label" :style="{backgroundColor: bgColor(index)}">
                    <span class="accent" :style="{opacity: 1 - opacityLevel(index)}" v-html="index + 1"/>
                    <span class="white" :style="{opacity: opacityLevel(index)}" v-html="index + 1"/>
                </div>

                <div class="inner-content">
                    <div class="title rw bottom">
                        <h3 class="_dot" v-html="item.title"/>
                    </div>

                    <div class="content">
                        <div v-html="item.body"/>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import moment from 'moment'
    import * as translations from '../modules/translations'
    import ScrollToPlugin from "gsap/ScrollToPlugin"
    import ScrollTrigger from "gsap/ScrollTrigger"

    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
</script>

<script>
    export default {
        props: ['content'],

        data() {
            return {
                loaded: false
            }
        },

        mounted() {
            let sections = gsap.utils.toArray(".timeline-item")

            const process = document.querySelector('.timeline-fixture');
            if ((typeof(process) != 'undefined' && process != null)) {
                let sections = gsap.utils.toArray('.timeline-item');
                gsap.to(sections, {
                    xPercent: -100 * (sections.length) + 100,
                    ease: "none",
                    scrollTrigger: {
                        trigger: process,
                        // markers: 1,
                        scrub: 1,
                        pin: true,
                        // snap: {
                        //     snapTo:  1 / (sections.length - 1), // snap to the closest label in the timeline
                        //     duration: { min: 0.2, max: 0.64 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                        //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                        //     ease: "expo.inOut", // the ease of the snap animation ("power3" by default)
                        // },
                        start: 'top top',
                        end: 'bottom top',
                    },
                });
            }
        },

        methods: {
            bgColor(index) {
                return 'rgba(var(--accent),' + ((1 / this.content.length) + 1 / this.content.length * index) + ')'
            },

            opacityLevel(index) {
                let half = this.content.length,
                    asd = ((1 / half) + 1 / half * index)
                return asd > 0.5 ? asd : 0
            },

            textColor(index) {
                let half = this.content.length,
                    asd = ((1 / half) + 1 / half * index)
                return 'rgba(var(--accent),' + ((1 / this.content.length) / this.content.length * index) + ')'
            },

            __(string, vars=[]) {
                return translations.translate(string, vars) 
            }
        },
    }
</script>