<template>
	<Renderer ref="renderer" antialias resize alpha :orbit-ctrl="{ enableDamping: true, dampingFactor: 0.05, enableZoom: false }" pointer shadow>
		<!-- @click="large = !large" -->
		<!-- antialias -->
		<!-- <Renderer ref="renderer" antialias alpha resize :orbit-ctrl="false" pointer shadow> -->
			<Camera :position="{ z: -4, y: 0, x: -5 }" :far="100"/>
			<Scene background="#ffffff">
				<AmbientLight color="#fff" />
				<PointLight :position="{ z: -20, y: 40 }" ref="light" color="#fff" :intensity="0.5"/>
				<GltfModel
				    src="/documents/2/untitled.gltf"
				    cast-shadow
				    receive-shadow
				>
			</GltfModel>

				<!-- <Plane :width="W*2" :height="1" :position="{ z: -10 }" receive-shadow>
					<PhongMaterial color="#fff" />
				</Plane> -->
					<!-- <BasicMaterial> -->
						<!-- <Texture src="media/images/mauri.original.png" /> -->
						  <!-- <PhongMaterial color="#ffffff" :props="{ transparent: true, opacity: 0.5 }" /> -->
					<!-- </BasicMaterial> -->
				<!-- </GltfModel> -->
			</Scene>

		<!-- <EffectComposer> -->
			<!-- <RenderPass /> -->
			<!-- <HalftonePass :radius="1" :scatter="0" /> -->
			<!-- <FilmPass/> -->
      <!-- <UnrealBloomPass :strength="0.4" /> -->
			<!-- <SMAAPass /> -->
		<!-- </EffectComposer> -->
	</Renderer>
</template>

<script>
	import { Object3D, Matrix4, InstancedBufferAttribute, Color } from 'three';
	import {
		AmbientLight,
		BoxGeometry,
		Camera,
		SubSurfaceMaterial,
		InstancedMesh,
		PhongMaterial,
		Plane,
		PointLight,
		Renderer,
		Scene
	} from 'troisjs';

	// import SimplexNoise from 'simplex-noise';
	// const simplex = new SimplexNoise();

	export default {
		components: {
			AmbientLight,
			BoxGeometry,
			SubSurfaceMaterial,
			Camera,
			InstancedMesh,
			PhongMaterial,
			Plane,
			PointLight,
			Renderer,
			Scene,
		},

		props: ["settings", "SIZE", "PADDING", "NX", "NY", "SIZEP", "W", "H", "NUM_INSTANCES", "COLORS"],

		data() {
			return {
				large: false
			}
		},

		computed: {
			set() {
				return {
					shadows: this.settings
				}
			}
		},

		mounted() {
			this.renderer = this.$refs.renderer
			this.pointer = this.renderer.three.pointer
			// this.imesh = this.$refs.imesh.mesh
			// this.light = this.$refs.light.light
			// this.light.position.z = -20
			this.dummy = new Object3D();
			this.renderer.onBeforeRender(this.animate);
		},
		methods: {
			animate() {
				// this.light.position.x = this.pointer.positionV3.x;
				// this.light.position.y = this.pointer.positionV3.y;
				// this.light2.position.x = this.pointer.positionV3.x;
				// this.light2.position.y = this.pointer.positionV3.y;
				// this.updateInstanceMatrix();


			},

			onReady(model){
				if (model) {
		            // model.traverse(o => {
		            //     if (o.isMesh){
		            //         // handle both multiple and single materials
		            //         const asArray = Array.isArray(o.material) ? o.material : [o.material]
		            //         // 0 works for matte materials - change as needed
		            //         asArray.forEach(mat => mat.metalness = 0)
		            //     }
		            // })
		        }
	        },


			updateInstanceMatrix() {
				this.dummy.updateMatrix();


				// const x0 = -this.W / 2 + this.PADDING;
				// const y0 = -this.H / 2 + this.PADDING;
				// const time = Date.now() * 0.0001;
				// const mx = this.pointer.positionN.x * 0.1;
				// const my = this.pointer.positionN.y * 0.1;
				// const noise = 0.005;
				// // let x, y, nx, ny, rx, ry;

				// // for (let i = 0; i < this.NX; i++) {
				// // 	for (let j = 0; j < this.NY; j++) {
				// // 		x = x0 + i * this.SIZEP;
				// // 		y = y0 + j * this.SIZEP;
				// 		this.dummy.position.set(x, y, 0);
						// this.large ? this.dummy.scale.setScalar(2) : this.dummy.scale.setScalar(1)
						// this.imesh.setMatrixAt(i * this.NY + j, this.dummy.matrix);
				// 	}
				// }

				// this.imesh.instanceMatrix.needsUpdate = true;
			},
		},
	};
</script>