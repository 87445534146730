'use strict'

import moment from 'moment'
import * as cookie from '../modules/cookies'

let setting = cookie.get('language')
let locale  = window.lang

moment.locale(locale)

export function setLanguage (id='nl') {
    cookie.set('language', id, 10)
}

export function getLanguage () {
    return cookie.get('language')
}

export function key (key) {
    let translated = key
    if (locale == 'en') return key
    if (!translateKeys[locale].hasOwnProperty(key)) {
        console.log('Translation not found: ', key)
    } else {
        translated = translateKeys[locale][key]
    }
    return translated
}

export function translate (string, variables=false) {
    let translated = string

    if (locale == 'en' && !variables) return string
    if (!translations[locale].hasOwnProperty(string)) {
        var no=true; 
        console.log('Translation not found: ', string)
    } else {
        translated = variables ? translations[locale][string].replace(/%%/g, (i => _ => variables[i++])(0)) : translations[locale][string]
    }

    return translated
}

export function exists (string, key=false) {
    if (key) return translateKeys[locale].hasOwnProperty(string) 
    return translations[locale].hasOwnProperty(string) 
}

let translations = {
    "nl": {
        "test": "Nederlands",
        "Step %%": "Stap %%",
        "Follow us": "Volg ons",
        "Close": "Sluiten",
        "More info": "Meer info",
        "Menu": "Menu",
        "Learn more about %%": "Meer over %%",
        "Posted %%": "Geplaatst op %%"
    }, 
    "fr": {
        "test": "French",
        "Step %%": "Étape %%",
        "Follow us": "Suivez-nous",
        "Close": "Fermer",
        "More info": "Plus d'informations",
        "Menu": "Menu",
        "Learn more about %%": "En savoir plus sur %%",
        "Posted %%": "Publié le %%"
    }, 
    "en": { // is default
    	"test": "English",
        "Step %%": "Step %%",
        "Follow us": "Follow us",
        "Close": "Close",
        "More info": "More info",
        "Menu": "Menu",
        "Learn more about %%": "Learn more about %%",
        "Posted %%": "Posted %%",
    }
}