<template>
    <div class="gallery-wrapper oh">
        <div class="cols cl center middle">
            <swiper @swiper="setControlledSwiper" @slideChange="onSlideChange" 
                :modules="[Pagination]"
                :speed="640"
                :autoplay="4000"
                :pagination="true"
                :initialSlide="1" 
                :centered-slides="true"
                :space-between="160" 
                slides-per-view="auto">
                <swiper-slide v-for="image in content">
                    <img :src="image.src" :alt="image.label ? image.label : 'Oqin image'"/>
                </swiper-slide>
                <swiper-pagination></swiper-pagination>
            </swiper>
        </div>
    </div>
</template>

<script setup>
    import gsap from 'gsap'
    import { Controller, Pagination } from 'swiper/modules';

    import { Swiper, SwiperSlide } from 'swiper/vue';
    import * as translations from '../modules/translations'
</script>

<script>
    export default {
        name: 'Gallery',
        props: ['content'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data() {
            return {
            }
        },

        methods: {
            __(string, vars) {
                return translations.translate(string, vars)
            },
        }
    };

</script>